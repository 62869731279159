import objectFitImages from "object-fit-images";
import React from "react";
import LazyLoad from "react-lazyload";
import { BREAKPOINTS } from "../../constants/mediaquerys";

import SImg from "./Styles/SImg";
// @ts-ignore
String.prototype.reverse = function () {
  return this.split("").reverse().join("");
};
// @ts-ignore
String.prototype.replaceLast = function (what, replacement) {
  // @ts-ignore
  return this.reverse()
    .replace(new RegExp(what.reverse()), replacement.reverse())
    .reverse();
};

const getSrcSetFromWidths = (src: string, srcSetWidths: IImageSrcSetWidths) => {
  let broze = `${src}?w=${srcSetWidths.bronze}`;

  let silver = srcSetWidths.silver
    ? `${src}?w=${srcSetWidths.silver}`
    : broze;

  let gold = srcSetWidths.gold ? `${src}?w=${srcSetWidths.gold}` : silver;

  let platinum = srcSetWidths.platinum
    ? `${src}?w=${srcSetWidths.platinum}`
    : gold;

  let diamond = srcSetWidths.diamond
    ? `${src}?w=${srcSetWidths.diamond}`
    : platinum;

  return {
    xSrc: broze,
    xSrcSet: `${broze} ${BREAKPOINTS.SILVER}w, ${silver} ${BREAKPOINTS.GOLD}w, ${gold} ${BREAKPOINTS.PLATINUM}w, ${platinum} ${BREAKPOINTS.DIAMOND}w, ${diamond} ${BREAKPOINTS.CHALLENGER}w`,
  };
};

class ImgInner extends React.PureComponent<any> {
  imgRef = React.createRef<any>();

  state = {
    loaded: false,
  };

  componentDidMount() {
    if (this.imgRef.current) {
      objectFitImages(this.imgRef.current);
      if (this.imgRef.current.complete) {
        this.setState({ loaded: true });
        this.props.onLoad && this.props.onLoad();
      } else {
        this.imgRef.current.onload = () => {
          objectFitImages(this.imgRef.current);
          this.setState({ loaded: true });
          this.props.onLoad && this.props.onLoad();
        };
      }
    }
  }

  componentWillUnmount() {
    this.imgRef.current.onload = null;
  }

  render() {
    const { src, alt, quality, children, srcSetWidths } = this.props;
    const { loaded } = this.state;
    const _src =
      src && typeof src === "string"
        ? src
            .replace("http:", "https:")
            .replace("res.cloudinary.com", "d5u6artv10pna.cloudfront.net")
            // @ts-ignore
            .replaceLast("/upload", `/upload/w_${quality || "50"},f_auto`)
        : "";

    if (srcSetWidths) {
      const { xSrc, xSrcSet } = getSrcSetFromWidths(_src, srcSetWidths);
      
      return (
        <SImg loaded={loaded} {...this.props}>
          <img src={xSrc} srcSet={xSrcSet} alt={alt} ref={this.imgRef} />
          {children}
        </SImg>
      );
    }

    return (
      <SImg loaded={loaded} {...this.props}>
        <img src={_src} alt={alt} ref={this.imgRef} />
        {children}
      </SImg>
    );
  }
}

export interface IImageSrcSetWidths {
  bronze: number;
  silver?: number;
  gold?: number;
  platinum?: number;
  diamond?: number;
}

export interface IImgProps {
  src: string /** link to file */;
  children?: any;
  alt?: string;
  placeholderHeight?: string /** lazyload preloader height ==> default: 0 */;
  offset?: string /** offset lazyload trigger ==> default: 0 */;
  quality?: number /** set the quality of the image in px/width ==> default: 50 */;
  lazyload?: boolean /** should the image be lazyloaded on scroll? ==> default: true */;
  onLoad?: () => void /** trigger when image is loaded ==> default: null */;
  srcSetWidths?: IImageSrcSetWidths;
}

const Img = ({ lazyload = true, ...props }: IImgProps) => {
  if (lazyload) {
    return (
      <LazyLoad
        height={
          props.placeholderHeight ? `${props.placeholderHeight}` : undefined
        }
        offset={props.offset}
      >
        <ImgInner {...props} />
      </LazyLoad>
    );
  }
  return <ImgInner {...props} />;
};

export default Img;
