
const productFields = [
  {
    field_title: "Luftschallemissionen in dB(A)",
    field_name: "AirborneNoiseEmission",
  },
  {
    field_title: "Luftschallemissionsklasse (Schleudergang)",
    field_name: "AirborneNoiseEmissionClass",
    blueprint_name: "ProductWashingMachine",
  },
  {
    field_title: "Luftschallemissionen des Schleudergangs in dB(A) re 1 pW",
    field_name: "AirborneNoiseEmissions",
  },
  {
    field_title: "Einbaugerät",
    field_name: "BuiltInDevice",
  },
  {
    field_title: "Beladung",
    field_name: "Capacity",
    blueprint_name: "ProductDryer",
  },
  {
    field_title: "Nennkapazität in kg für das Programm 'eco 40-60'",
    field_name: "Capacity",
    blueprint_name: "ProductWashingMachine",
  },
  {
    field_title: "Kategorie",
    field_name: "Category",
  },
  {
    field_title: "Farbauswahl",
    field_name: "color",
    blueprint_name: "ProductBathroomFurniture",
  },
  {
    field_title: "Farbe",
    field_name: "color",
    blueprint_name: "ProductSinkAndTap",
  },
  {
    field_title: "Kondens oder Wärmepumpentrockner?",
    field_name: "CondensationOrHeatPumpDryer",
  },
  {
    field_title: "Konnektivität",
    field_name: "Connectivity",
  },
  {
    field_title: "Garraum/Nutzbares Volumen",
    field_name: "CookingChamberUsableVolume",
  },
  {
    field_title: "Tiefe in cm",
    field_name: "depth",
  },
  {
    field_title: "Trocknungseffizienzklasse",
    field_name: "DryingEfficiencyClass",
  },
  {
    field_title: "EAN Nummer",
    field_name: "EAN",
  },
  {
    field_title: "Energieverbrauch in kWh bei 100 Waschzyklen",
    field_name: "EnergyConsumption",
    blueprint_name: "ProductWashingMachine",
  },
  {
    field_title: "Energieverbrauch in kWh pro 100 Programmdurchläufen",
    field_name: "EnergyConsumption",
    blueprint_name: "ProductDishwasher",
  },
  {
    field_title: "Energieverbrauch pro Jahr in kWh",
    field_name: "EnergyConsumption",
    blueprint_name: "ProductCoffeeMachine",
  },
  {
    field_title: "Energieverbrauch im Heißluft- Betrieb in kWh/Zyclus",
    field_name: "energyConsumptionHotAir",
  },
  {
    field_title:
      "Energieverbrauch im konventionellen Ober-/Unterhitze Betrieb in kWh/Zyclus",
    field_name: "energyConsumptionTopBottomHeat",
  },
  {
    field_title: "Energieeffizienzklasse",
    field_name: "EnergyEfficiencyClass",
  },
  {
    field_title: "Abluft/Umluft",
    field_name: "ExhaustAirOrCirculatingAir",
  },
  {
    field_title: "Farbe",
    field_name: "farbe",
  },
  {
    field_title: "Filterleistung",
    field_name: "FilterPerformance",
  },
  {
    field_title: "Kaffeevollautomat",
    field_name: "FullyAutomaticCoffeeMachine",
  },
  {
    field_title: "Größe",
    field_name: "grosse",
  },
  {
    field_title: "Muldenlüfter/ Downdraft",
    field_name: "HasTroughfanOrDowndraft",
  },
  {
    field_title: "Höhe in cm",
    field_name: "hight",
  },
  {
    field_title: "Art - Induktion, Flexinduktion, Strahlung, Gas",
    field_name: "InductionType",
  },
  {
    field_title: "Küchenart",
    field_name: "kuchenart",
  },
  {
    field_title: "Küchenform",
    field_name: "kuchenform",
  },
  {
    field_title: "Kücheninsel",
    field_name: "kucheninsel",
  },
  {
    field_title: "Küchenstil",
    field_name: "kuchenstil",
  },
  {
    field_title: "Material",
    field_name: "Martial",
  },
  {
    field_title: "Material",
    field_name: "material",
  },
  {
    field_title: "Mehrwertsteuer in Prozent",
    field_name: "mehrwertsteuer",
  },
  {
    field_title: "Schallemission",
    field_name: "NoiseEmission",
  },
  {
    field_title: "Nennkapazität in Maßgedecken",
    field_name: "NominalCapacity",
  },
  {
    field_title: "Leistung",
    field_name: "Power",
  },
  {
    field_title: "Dauer des Eco-Programms",
    field_name: "programDuration",
    blueprint_name: "ProductDishwasher",
  },
  {
    field_title: "Dauer des Programms 'eco 40-60' bei Nennkapazität",
    field_name: "programDuration",
    blueprint_name: "ProductWashingMachine",
  },
  {
    field_title: "Schontrommel",
    field_name: "ProtectiveDrum",
  },
  {
    field_title: "Pyrolyse",
    field_name: "Pyrolysis",
  },
  {
    field_title: "Schleudereffizienzklasse",
    field_name: "SpinEfficiencyClass",
  },
  {
    field_title: "Max. Schleuderdrehzahl",
    field_name: "SpinningRate",
  },
  {
    field_title: "Sternkennzeichnung",
    field_name: "StarRating",
  },
  {
    field_title: "Dampfgarer",
    field_name: "steamCooker",
  },
  {
    field_title: "Technik",
    field_name: "Technology",
  },
  {
    field_title: "Transportmöglichkeiten- und kosten",
    field_name: "transportNote",
  },
  {
    field_title: "Typ (Becken, Auszug etc.)",
    field_name: "Type",
  },
  {
    field_title: "Nutzinhalt Kühlfach",
    field_name: "UsableCapacityCoolerCompartment",
  },
  {
    field_title: "Nutzinhalt Gefrierfach",
    field_name: "UsableCapacityFreezerCompartment",
  },
  {
    field_title: "Nutzbares Volumen",
    field_name: "UsableVolume",
  },
  {
    field_title: "Wasserverbrauch in Litern für einen Programmdurchlauf",
    field_name: "WaterConsumption",
    blueprint_name: "ProductWashingMachine",
  },
  {
    field_title: "Wasserverbrauch in Litern pro Programmdurchlauf",
    field_name: "WaterConsumption",
    blueprint_name: "ProductDishwasher",
  },
  {
    field_title: "Gewicht in kg",
    field_name: "weight",
  },
  {
    field_title: "Breite in cm",
    field_name: "width",
    blueprint_name: "ProductAccessoriesAndSupplies",
  },
  {
    field_title: "0 Grad Zone",
    field_name: "ZeroDegreeZone",
  },
  {
    field_title: "Zielgruppe",
    field_name: "zielgruppe",
  },
];

export const getProductFieldTitle = ({
  productTypename,
  fieldName,
}): string => {
  const fieldTitle = productFields.find((productField) => {
    return (
      productField.field_name === fieldName &&
      (productField.blueprint_name === undefined ||
        productField.blueprint_name === productTypename)
    );
  })?.field_title;

  if (!fieldTitle) {
    return "";
  }

  return fieldTitle;
};
