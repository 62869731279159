import React from "react";

function IconDownload() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 14 19">
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="currentColor" transform="translate(-383 -213)">
                    <g transform="translate(383 213.5)">
                        <path d="M14 6h-4V0H4v6H0l7 7 7-7zM0 15v2h14v-2H0z"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default IconDownload;
